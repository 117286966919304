#particle {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    /* Prevent vertical scrollbar due to fixed background */
    /* color: #e4dddd; */
  }
  .sem{
    color: #000000;
  }

